import React, { Component, Fragment, ReactText } from 'react';
import SessionComponent from './SessionComponent';
import './css/MyCADDataPage.scss';
import { MyPageProps, pageContent } from './MyPage';
import NextIcon from '@material-ui/icons/NavigateNext'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import {ReactComponent as DownloadIcon} from './img/icon/download.svg';
import AWSModule from './AWSModule';
import AWS from 'aws-sdk';
import Pagination from './Pagination';
import { Button, Table, TableHead, TableCell, TableBody, TableRow, Input, InputAdornment, Select, Checkbox, ClickAwayListener, IconButton, } from '@material-ui/core'
import { Link } from 'react-router-dom';
import App from './App';
import S3Image from './S3Image';
import * as URI from "uri-js";
import BuilditInput, { BuilditInputProps } from './BuilditInput';
import moment from 'moment';
import Tooltip from './Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Modal, { ModalOptions } from './Modal';
import { default as _ } from 'lodash';
import BuilditSelect from './BuilditSelect';
import JSZip from 'jszip';

export type ModalType = "DELETE" | "DOWNLOAD";

interface MyCADDataPageState {
  MyCADData: any[],
  selectedData: Array<{cardId: string, checked: boolean, modal_checked: boolean,}>,
  totalNum: number,
  totalItemNum: number,
  viewNum: number,
  curPage: number,
  DBTableName: string,
  S3BucketName: string,
  searchField: "name" | "global_id",
  searchText: string,
  modalOptions?: ModalOptions,
  openModal: boolean,
  checkAll: boolean;
  modalCheckAll: boolean;
}

interface MyCADDataPagetProps {
  DBTableName: string,
  S3BucketName: string,
  pageContent: pageContent,
}

export default class MyCADDataPage extends SessionComponent<MyCADDataPagetProps, MyCADDataPageState> {
  state: MyCADDataPageState = {
    MyCADData: [],
    selectedData: [],
    totalNum: 1,
    curPage: 1,
    viewNum: 8,
    totalItemNum: 1,
    DBTableName: App.DDBTable.MyBuildingSite,
    S3BucketName: App.S3Bucket.MyBuildingSite,
    searchField: "name",
    searchText: "",
    openModal: false,
    checkAll: false,
    modalCheckAll: true,
  }
  componentWillMount = async () => {
    this.onPageChange(1);
  }

  componentDidUpdate = (preProps: Readonly<MyCADDataPagetProps>, preState: Readonly<MyCADDataPageState>) => {
    if (preProps.DBTableName !== this.props.DBTableName || 
      preProps.S3BucketName !== this.props.S3BucketName ||
      preState.searchField !== this.state.searchField ||
      preState.searchText !== this.state.searchText) {
      this.onPageChange(1);
    }

    // list 초기화될때 상단 check 해제
    if (this.state.checkAll) {
      if (this.state.selectedData.length === 0) {
        this.setState({ checkAll: false })
      } else {
        for (let i = 0; i <this.state.selectedData.length; i++) {
          if (this.state.selectedData[i].checked === false) {
            this.setState({ checkAll: false })
            return;
          }
        }
      }
    }
  }

  getRenderSites = async (startNum: number) => {
    let q = `stage.keyword:${App.stage} AND email.keyword:${App.session.email} AND deleted:false`
    if (this.state.searchText.length !== 0) {
      if (this.state.searchField === 'name') {
        q += ` AND name.keyword:/.*${this.state.searchText.toString().replace(/[()\*\[\]{}:=\'\"%\&\?\+]/g, '\\$&')}.*/`
      } else {
        q += ` AND ${this.state.searchField}:${this.state.searchText}`
      }      
    }
    const r = await App.search({
      "table": this.props.DBTableName,
      "query": q,
      "from": startNum,
      "size": 8,
      "sort": [
        {
          "created_at": "desc",
        },
      ],
    });

    return {
      Payload: JSON.stringify(r.data),
    }
  }

  onPageChange = async (page: number) => {
    const r = await this.getRenderSites((page - 1) * 8);
    let hits = JSON.parse(r.Payload as string);
    let mySites = hits.hits.hits;
    let selected = mySites.map((e: any) => {return {cardId: e._source.global_id, checked: false, modal_checked: false,}})

    if (mySites.length === 0) {
      page -= 1;
      if (page < 1) {
        mySites = [];
        selected = []
      }
      else {
        const r = await this.getRenderSites((page - 1) * 8);
        let nhits = JSON.parse(r.Payload as string);
        mySites = nhits.hits.hits;
        selected = mySites.map((e: any) => {return {cardId: e._source.global_id, checked: false, modal_checked: false,}})
      }
    }
    this.setState({
      DBTableName: this.props.DBTableName,
      S3BucketName: this.props.S3BucketName,
      MyCADData: mySites,
      selectedData: selected,
      curPage: page,
      totalNum: Math.ceil(hits.hits.total),
      totalItemNum: hits.hits.total,
      modalCheckAll: true,
      checkAll: false,
    })
  }

  syncItem = async (card: any, name: string) => {
    const list = _.cloneDeep(this.state.MyCADData);
    const idx = list.findIndex(e => e._source.global_id === card.global_id)
    list[idx]._source.name = name;

    this.setState({MyCADData: list});
  }

  deleteItem = async (item: AWS.DynamoDB.DocumentClient.AttributeMap) => {
    item.deleted = true;

    const aws = await new AWSModule("LAMBDA").connect();
    await aws.Lambda!.invoke({
      FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
      Payload: JSON.stringify({
        TableName: this.props.DBTableName,
        Item: item,
      })
    }).promise();

    this.onPageChange(this.state.curPage);
  }

  deleteCheckedItem = async () => {
    this.setModal(true, this.getModalOption("DELETE"));
  }

  downloadCheckedItem = async () => {
    this.setModal(true, this.getModalOption("DOWNLOAD"));
  }

  setCheckCard = (cardId: string) => {
    let list = this.state.selectedData.map(e => {
      if (e.cardId === cardId) {
        return {cardId: e.cardId, checked: !e.checked, modal_checked: !e.checked}
      } else {
        return e
      }
    });
    
    let allChecked = true;
    for (let i = 0; i < list.length; i++) {
      if (list[i].checked === false) {
        allChecked = false;
      }
    }

    this.setState({selectedData: list, checkAll: allChecked});
  }

  setCheckCardAll = (checkAll: boolean) => {
    this.setState({
      checkAll: checkAll,
      modalCheckAll: checkAll,
      selectedData: this.state.selectedData.map(e =>{
        return {
          cardId: e.cardId,
          checked: checkAll,
          modal_checked: checkAll,
        }
      })
    })
  }

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      openModal: open,
      modalOptions: options
    });
  }

  setModalChecked = (cardId: string[], modal_type: ModalType, allCheck?: boolean) => {
    const list = _.cloneDeep(this.state.selectedData);
    cardId.map(p => {
      const index = list.findIndex(e => e.cardId === p);
      if (index > -1) {
        if (list[index].checked) {
          if (allCheck !== undefined) {
            list[index].modal_checked = allCheck;
          } else {
            list[index].modal_checked = !list[index].modal_checked;
          }
        }
      }
    });

    let allChecked = true;
    for (let i = 0; i < list.length; i++) {
      if (list[i].checked) {
        if (list[i].modal_checked === false) {
          allChecked = false;
        }
      }
    }

    this.setState({ selectedData: list, modalCheckAll: allChecked, }, () => {
        this.setModal(true, this.getModalOption(modal_type));
    });
  }

  setModalCheckedAll = (modal_type: ModalType, allCheck: boolean) => {
    this.setState({
      modalCheckAll: allCheck,
      selectedData: this.state.selectedData.map(e =>{
        return {
          cardId: e.cardId,
          checked: e.checked,
          modal_checked: e.checked && allCheck,
        }
      })
    }, () => {this.setModal(true, this.getModalOption(modal_type))});
  }

  refesh = () => {
    this.setModal(false);
    this.onPageChange(this.state.curPage);
  }

  getModalOption = (type: ModalType) : ModalOptions | undefined  => {
    let option: ModalOptions | undefined;

    switch (type) {
      case "DELETE":
        {
          let list: Array<{cardId: string, modal_checked: boolean}> = [];

          const checkedData = this.state.selectedData.filter(e => e.checked)
          if (checkedData.length === 0) {
            option = {
              color: "DARK",
              type: "SIMPLE",
              positive: () => {
                this.setModal(false);
                this.syncCheckedProject();
              },
              negative: "hidden",
              content: <div>선택된 파일이 없습니다.</div>
            }
          } else {
            if (this.state.MyCADData === undefined) {
              return undefined;
            }
  
            for (let i = 0; i < this.state.selectedData.length; i++) {
              if (this.state.selectedData[i].checked) {
                list.push({
                  cardId: this.state.selectedData![i].cardId,
                  modal_checked: this.state.selectedData[i].modal_checked,
                });
              }
            }
            
            option = {
              positive: async () => {
                const lambda = await new AWSModule("LAMBDA").connect();
                const ddb = await new AWSModule("DDB").connect();
                for (let aCheckedItem of checkedData) {

                  if (aCheckedItem.checked && aCheckedItem.modal_checked) {
                    let item = await ddb.Ddb!.get({
                      TableName: this.props.DBTableName,
                      Key: {
                        stage: App.stage,
                        global_id: aCheckedItem.cardId,
                      }
                    }).promise();
            
                    if (item.Item) {
                      item.Item.deleted = true
                      await lambda.Lambda!.invoke({
                        FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
                        Payload: JSON.stringify({
                          TableName: this.props.DBTableName,
                          Item: item.Item,
                        })
                      }).promise();
                    }
                  }
                }
                this.setModal(false);          
                this.onPageChange(this.state.curPage);
              },
              negative: () => {
                this.syncCheckedProject();
                this.setModal(false);
              },
              title: "삭제 안내",
              color: "DARK",
              type: "NORMAL",
              positiveTitle: "삭제",
              content: (
                <div className="cad-data-modal">
                <div className="table-wrapper">
                  <Table className="table">
                    <TableHead className="table-head">
                      <TableRow className="table-row">
                        <TableCell className="table-cell checkbox">
                          <Checkbox className={`checkbox ${this.state.modalCheckAll && "checked" || ""}`} disableRipple={true} checked={this.state.modalCheckAll}
                            onClick={e => {
                              this.setModalCheckedAll("DELETE", !this.state.modalCheckAll,);
                            }}
                          />
                        </TableCell>
                        <TableCell className="table-cell project-id"><div>번호</div></TableCell>                      
                        <TableCell className="table-cell project-name"><div className="head">파일명</div></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                    {
                      list.map((p, i) => {
                        const file_data = this.state.MyCADData.find(e => e._source.global_id === p.cardId);
                        if (file_data === undefined) {
                          return;
                        }
                        const f = file_data._source;
                        return (
                          <TableRow className="table-row">
                            <TableCell className="table-cell checkbox">
                              <Checkbox className={`checkbox ${this.state.selectedData[this.state.selectedData.findIndex(e => e.cardId === p.cardId)].modal_checked && "checked" || ""}`}
                              checked={this.state.selectedData[this.state.selectedData.findIndex(e => e.cardId === p.cardId)].modal_checked}
                              onClick= {e => {
                                this.setModalChecked([p.cardId!], "DELETE");
                              }} />
                            </TableCell>
                            <TableCell className="table-cell project-id"><div>{f.user_id}</div></TableCell>
                            <TableCell className="table-cell project-name"><div style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}> {f.name}</div></TableCell>
                          </TableRow>
                        )
                      })
                    }
                    {
                      list.length === 0 &&
                      <TableRow className="table-row">
                        <TableCell colSpan={5} className="table-cell no-data">
                        {
                          "선택된 파일이 없습니다"
                        }</TableCell>
                      </TableRow>
                    }
                    </TableBody>
                  </Table>
                </div>
                <div className="info-wrap">
                  <div className="info">* 삭제된 파일은 복구하실 수 없습니다.</div>
                </div>
              </div>)
            }
          }
        }
        break;
        case "DOWNLOAD":
          {
            let list: Array<{cardId: string, modal_checked: boolean}> = [];
  
            const checkedData = this.state.selectedData.filter(e => e.checked)
            if (checkedData.length === 0) {
              option = {
                color: "DARK",
                type: "SIMPLE",
                positive: () => {
                  this.setModal(false);
                  this.syncCheckedProject();
                },
                negative: "hidden",
                content: <div>선택된 파일이 없습니다.</div>
              }
            } else {
              if (this.state.MyCADData === undefined) {
                return undefined;
              }
    
              for (let i = 0; i < this.state.selectedData.length; i++) {
                if (this.state.selectedData[i].checked) {
                  list.push({
                    cardId: this.state.selectedData![i].cardId,
                    modal_checked: this.state.selectedData[i].modal_checked,
                  });
                }
              }
              
              option = {
                positive: async () => {
                  const modal_checked_data = checkedData.filter(e => e.checked && e.modal_checked);
                  if (modal_checked_data.length === 1) {
                    const file_data = this.state.MyCADData.find(e => e._source.global_id === modal_checked_data[0].cardId);
                    const r = await App.s3!.getSignedUrl("getObject", {
                      Bucket: this.props.S3BucketName,
                      Key: `${App.stage}/${checkedData[0].cardId}/file.dxf`,
                      Expires: 43200,
                      ResponseContentDisposition: `attachment; filename="${encodeURIComponent(file_data._source.name)}.dxf"`
                    }, async (err, data) => {
                      if (err) {
                        this.setModal(false);
                        this.setModal(true, {
                          color: "DARK",
                          type: "SIMPLE",
                          positive: () => {
                            this.setModal(false);
                            this.onPageChange(this.state.curPage);
                          },
                          negative: "hidden",
                          content: (
                            <Fragment>
                              <div>다음 파일을 받을 수 없습니다.</div>
                              <div>{file_data._source.name}</div>
                            </Fragment>
                          )
                        });
                      } else {
                        window.location.href = data;
                        this.setModal(false);
                        this.onPageChange(this.state.curPage);
                      }});
                  } else {
                    const zip = new JSZip();
                    const err_list = new Array<string>();
                    let list: Array<{key: string, fileNmae: string}> = [];

                    for (let aCheckedItem of modal_checked_data) {
                      if (aCheckedItem.checked && aCheckedItem.modal_checked) {
                        const file_data = this.state.MyCADData.find(e => e._source.global_id === aCheckedItem.cardId);
                        list.push({
                          key: `${App.stage}/${aCheckedItem.cardId}/file.dxf`,
                          fileNmae: `${file_data._source.name}.dxf`,
                        });
                      }
                    }

                    await Promise.all(list.map(async e => {                        
                      const r = await App.s3!.getObject({
                        Bucket: this.props.S3BucketName,
                        Key: e.key,
                      }).promise();
                      
                      if (r) {
                        zip.file(e.fileNmae, r.Body as Buffer);
                      } else {
                        err_list.push(e.fileNmae);
                      }
                    }));

                    if (err_list.length > 0) {
                      this.setModal(false);
                      this.setModal(true, {
                        color: "DARK",
                        type: "SIMPLE",
                        positive: () => {
                          this.setModal(false);
                          this.onPageChange(this.state.curPage);
                        },
                        negative: "hidden",
                        content: (
                          <Fragment>
                            <div>다음 파일을 받을 수 없습니다.</div><br/>
                            {
                              err_list.map(e => <div>{e}</div>)
                            }
                            <br/>
                            <div>위 파일을 제외하고 다시 시도해 주십시오</div>
                          </Fragment>
                        )
                      });
                    } else {
                      const buf = await zip.generateAsync({
                        type: "arraybuffer",
                      });
  
                      const url = window.URL.createObjectURL(new Blob([buf]));
                      const a = document.createElement('a');
                      a.style.display = 'none';
                      a.href = url;
                      // the filename you want
                      a.download = `나의_${this.props.pageContent}.zip`;
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);

                      this.setModal(false);          
                      this.onPageChange(this.state.curPage);
                    }
                  }
                  
                },
                negative: () => {
                  this.syncCheckedProject();
                  this.setModal(false);
                },
                title: "다운로드 안내",
                color: "DARK",
                type: "NORMAL",
                positiveTitle: "다운로드",
                content: (
                  <div className="cad-data-modal">
                  <div className="table-wrapper">
                    <Table className="table">
                      <TableHead className="table-head">
                        <TableRow className="table-row">
                          <TableCell className="table-cell checkbox">
                            <Checkbox className={`checkbox ${this.state.modalCheckAll && "checked" || ""}`} disableRipple={true} checked={this.state.modalCheckAll}
                              onClick={e => {
                                this.setModalCheckedAll("DOWNLOAD", !this.state.modalCheckAll,);
                              }}
                            />
                          </TableCell>
                          <TableCell className="table-cell project-id"><div>번호</div></TableCell>                      
                          <TableCell className="table-cell project-name"><div className="head">파일명</div></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table-body">
                      {
                        list.map((p, i) => {
                          const file_data = this.state.MyCADData.find(e => e._source.global_id === p.cardId);
                          if (file_data === undefined) {
                            return;
                          }
                          const f = file_data._source;
                          return (
                            <TableRow className="table-row">
                              <TableCell className="table-cell checkbox">
                                <Checkbox className={`checkbox ${this.state.selectedData[this.state.selectedData.findIndex(e => e.cardId === p.cardId)].modal_checked && "checked" || ""}`}
                                checked={this.state.selectedData[this.state.selectedData.findIndex(e => e.cardId === p.cardId)].modal_checked}
                                onClick= {e => {
                                  this.setModalChecked([p.cardId!], "DOWNLOAD");
                                }} />
                              </TableCell>
                              <TableCell className="table-cell project-id"><div>{f.user_id}</div></TableCell>
                              <TableCell className="table-cell project-name"><div style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}> {f.name}</div></TableCell>
                            </TableRow>
                          )
                        })
                      }
                      {
                        list.length === 0 &&
                        <TableRow className="table-row">
                          <TableCell colSpan={5} className="table-cell no-data">
                          {
                            "선택된 파일이 없습니다"
                          }</TableCell>
                        </TableRow>
                      }
                      </TableBody>
                    </Table>
                  </div>
                </div>)
              }
            }
          }
          break;
      default:
        option = undefined;
        break;
    }

    return option;
  }

  syncCheckedProject = () => {
    const list = _.cloneDeep(this.state.selectedData);
    const return_list = list.map(e => {
      return {
        cardId: e.cardId,
        checked: e.checked && e.modal_checked,
        modal_checked: e.checked && e.modal_checked,
      }
    });
    
    this.setState({selectedData: return_list, modalCheckAll: true});
  }

  render = () => {
    let itemDiv = <div className='noItemText'>
      {this.props.pageContent} 파일이 없습니다.<br/>
      [{this.props.pageContent} 불러오기]로 업로드 해주세요
    </div>
    let cadUrl = "";

    if (this.props.pageContent === pageContent.myPlan) {
      cadUrl = "/cad/MyBuildingPlan";
    } else if (this.props.pageContent === pageContent.mySite) {
      cadUrl = "/cad/MySite";
    } else if (this.props.pageContent === pageContent.myBuildingType) {
      cadUrl = "/cad/MyBuildingType";
    }

    if (this.state.MyCADData.length > 0) {
      itemDiv = <div>
        <div className='cardTable'>{
          this.state.MyCADData.map((s, i) => {
            const curCard = this.state.selectedData.find(e => e.cardId === s._source.global_id)
            return (<MySiteCard
              {...this.props}
              card={s._source}
              key={s._id}
              onDelete={this.deleteItem}
              DBTableName={this.state.DBTableName}
              S3BucketName={this.state.S3BucketName}
              pageContent={this.props.pageContent}
              margin={(i % 4 == 3) ? 0 : 20}
              checked={(curCard !== undefined) ? curCard.checked : false}
              setCheck={this.setCheckCard}
              setModal={this.setModal}
              getModalOption={this.getModalOption}
              syncItem={this.syncItem}
              refresh={this.refesh}
            ></MySiteCard>)
          })
        }</div>
        <Tooltip msg="삭제">
          <Button className="bottom-button btn bg-navy btn-cancel" onClick={this.deleteCheckedItem} ><DeleteIcon /></Button>
        </Tooltip>
        <Tooltip msg="DXF파일 내려받기">
          <Button className="bottom-button not-first btn bg-navy btn-primary" onClick={this.downloadCheckedItem} ><DownloadIcon /></Button>
        </Tooltip>
        <div className="pagination">
          <Pagination totalNum={this.state.totalNum} viewNum={this.state.viewNum} curPage={this.state.curPage} onChange={this.onPageChange} />
        </div>
      </div>
    }

    let information = <div />;
    switch (this.props.pageContent) {
      case pageContent.myBuildingType:
      case pageContent.mySite:
        information = <div>나의 {this.props.pageContent}</div>
        break;
      case pageContent.myPlan:
        information = <div><span>나의 {this.props.pageContent}</span>을 기준으로 빌드잇 디자이너를 실행할 수 있습니다.</div>
        break;
      default:
        break;
    }

    const tooltipText = `[${this.props.pageContent} 불러오기]버튼으로 캐드 파일을 업로드하실 수 있습니다.`

    return (
      <Fragment>
        <div className='sitePageContent'>
          <div className='siteInfo font font-primary font-noto font-18px'>{information}
            <Tooltip msg={tooltipText} className="tooltip">
            <div className='icon'>
              <object type="image/svg+xml" data="/img/my_page/icon_help.svg"/>
            </div>
            </Tooltip>
          </div>
          <div className='interactionLayer'>
            <div className='search-wrapper'>
              <BuilditSelect className="search-field-select font bg-navy font-primary font-noto font-14px"
                list={[{label: "제목", value: "name"}, {label: "번호", value: "global_id"}]}
                value={this.state.searchField}
                onChange={e => {(this.setState({searchText: "", searchField: e as "name" | "global_id"}))}}
                type="Normal"
              >
              </BuilditSelect>
              <BuilditInput className="input-search font bg-navy font-emphasis font-noto font-14px"
                placeholder={"검색"}
                endAdornment={<InputAdornment position="end">
                  <InputAdornment position="end">
                    {
                      this.state.searchText.length > 0 &&
                      <IconButton className="icon-btn" disableRipple={true} onClick={e => { this.setState({ searchText: "" }) }}>
                        <CloseIcon className="icon remove-icon" />
                      </IconButton>
                    }
                      <IconButton className="icon-btn" disableRipple={true} onClick={e => {}}>
                        <SearchIcon className="icon" />
                      </IconButton>
                    </InputAdornment>
                </InputAdornment>}
                type={'text'}
                value={(this.state.searchText.length > 0 && this.state.searchText) || ''}
                onChange={e => {
                  this.setState({searchText: e as string})
                }}
              />
              {
                this.state.searchText && 
                <div className="search-info font bg-navy font-primary font-noto font-14px">
              {
                this.state.totalNum > 0 &&
                <Fragment>
                  {
                    <Fragment>
                      <span>
                        <span>
                        { "'" + this.state.searchText + "'"}
                        </span>
                      {"에 대한 "}
                      <span>{this.state.totalNum}</span>
                      <span>개의 검색결과</span>
                      </span>
                    </Fragment>
                  }
                </Fragment> ||
                <Fragment>
                  <span>
                    <span style={{marginRight: "5px"}}>
                    { "'" + this.state.searchText + "'"}
                    </span>
                  에 대한 검색 결과가 없습니다
                  </span> 
                </Fragment>
              }
              </div>
              }
            </div>
          </div>
          <div className="file-layer">
            <div className="left-wrapper">
              <Checkbox className={`check-box checkbox ${this.state.checkAll && "checked" || ""}`} disableRipple={true} checked={this.state.checkAll}
                onClick={e => {
                  this.setCheckCardAll(!this.state.checkAll)
                }}
              />
            </div>
            <div className="right-wrapper">
              <Button className="file-button btn bg-navy btn-primary" disableRipple={true}
              onClick={e => window.open(cadUrl, "_blank")}>
                <div className="buttonText">DXF파일 불러오기</div>
                <OpenInBrowserIcon className="icon"/>
              </Button>
              <Button className="file-button not-first btn bg-navy btn-primary" disableRipple={true}
                onClick={this.downloadCheckedItem}>
                <div className="buttonText">DXF파일 내려받기</div>
                <DownloadIcon className="icon"/>
              </Button>
              <Tooltip msg="삭제">
                <Button className="delete-Button btn bg-navy btn-cancel" disableRipple={true}
                  onClick={this.deleteCheckedItem}>
                  <DeleteIcon className="icon"/>
                </Button>
              </Tooltip>
            </div>
          </div>
          {itemDiv}
        </div>
        {
        this.state.openModal && 
        <Modal
            open={this.state.openModal}
            type={this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type || "SIMPLE"}
            positive={this.state.modalOptions && this.state.modalOptions.positive}
            negative={this.state.modalOptions && this.state.modalOptions.negative}
            title={this.state.modalOptions && this.state.modalOptions.title}
            negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
            positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
            content={this.state.modalOptions && this.state.modalOptions.content}
            color={this.state.modalOptions && this.state.modalOptions.color || "DARK"}
        />
      }
      </Fragment>
      
      
    )
  }
}

interface MySiteCardProps extends MyPageProps {
  DBTableName: string,
  S3BucketName: string,
  card: any,
  pageContent: pageContent,
  onDelete: (item: AWS.DynamoDB.DocumentClient.AttributeMap) => void,
  margin: number,
  checked: boolean,
  setCheck: (cardId: string) => void,
  setModal: (open: boolean, options?: ModalOptions) => void;
  getModalOption: (type: ModalType) => ModalOptions | undefined;
  syncItem: (card: any, name: string) => void;
  refresh: () => void;
}

interface MySiteCardState {
  name: string,
  focusOnNameTag: boolean,
  item: AWS.DynamoDB.DocumentClient.AttributeMap,
  isImageHover: boolean,
}

class MySiteCard extends Component<MySiteCardProps, MySiteCardState> {
  state: MySiteCardState = {
    name: this.props.card.name,
    focusOnNameTag: false,
    item: {},
    isImageHover: false,
  }

  componentWillMount = async () => {
    await this.getItem();
  }

  getItem = async () => {
    const aws = await new AWSModule("DDB").connect();
    let item = await aws.Ddb!.get({
      TableName: this.props.DBTableName,
      Key: {
        stage: App.stage,
        global_id: this.props.card.global_id,
      },
    }).promise();

    if (item.Item) {
      this.setState({
        name: this.props.card.name,
        focusOnNameTag: false,
        item: item.Item,
      })
    }
  }

  componentDidUpdate = async (preProps: Readonly<MySiteCardProps>, preState: Readonly<MySiteCardState>) => {
    if (preProps.DBTableName !== this.props.DBTableName || preProps.S3BucketName !== this.props.S3BucketName) {
      await this.getItem();
    }
  }

  nameChanged = (e: string) => {
    this.setState({
      name: e,
    })
  }

  nameOnFocus = () => {
    this.setState({
      focusOnNameTag: true,
    })
  }

  nameOnBlur = async () => {
    const aws = await new AWSModule("DDB").connect();
    if (this.state.item.name !== this.state.name) {
      this.state.item.name = this.state.name;

      await aws.Ddb!.put({
        TableName: this.props.DBTableName,
        Item: this.state.item,
      }).promise();

      await this.props.syncItem(this.props.card, this.state.name);
    }

    this.setState({
      focusOnNameTag: false,
    });    
  }

  delteFile = async () =>{
    this.props.setModal(true, {
      color: "DARK",
      type: "SIMPLE",
      positive: async () => {
        const lambda = await new AWSModule("LAMBDA").connect();
        const ddb = await new AWSModule("DDB").connect();
        
        let item = await ddb.Ddb!.get({
          TableName: this.props.DBTableName,
          Key: {
            stage: App.stage,
            global_id: this.props.card.global_id,
          }
        }).promise();

        if (item.Item) {
          item.Item.deleted = true
          await lambda.Lambda!.invoke({
            FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
            Payload: JSON.stringify({
              TableName: this.props.DBTableName,
              Item: item.Item,
            })
          }).promise();
        }
        this.props.refresh();
      },
      negative: () => {
        this.props.setModal(false);
      },
      positiveTitle: "삭제",
      content: <div>{`정말로 파일을 삭제 하시겠습니까?"`}</div>
    })
  }

  downloadFile = async () => {
    const r = await App.s3!.getSignedUrl("getObject", {
      Bucket: this.props.S3BucketName,
      Key: `${App.stage}/${this.props.card.global_id}/file.dxf`,
      Expires: 43200,
      ResponseContentDisposition: `attachment; filename="${encodeURIComponent(this.state.name)}.dxf"`
    }, async (err, data) => {
      if (err) {
        this.props.setModal(false);
        this.props.setModal(true, {
          color: "DARK",
          type: "SIMPLE",
          positive: () => {
            this.props.setModal(false);
            this.props.refresh();
          },
          negative: "hidden",
          content: <div>파일을 받을 수 없습니다.</div>,
        });
      } else {
        window.location.href = data;
        this.props.setModal(false);
        this.props.refresh();
      }});
  }

  render = () => {
    // const s3 = new AWSModule("S3");

    // let icon = <EditIcon className='editIcon' />;
    // if (this.state.focusOnNameTag) {
    //   icon = <div></div>;
    // }

    let infoTable = <table></table>

    switch (this.props.pageContent) {
      case pageContent.mySite:
        infoTable = <div className='inofTable'>
          <div className='infoRow'>
            <div className='rowTitle'>사업영역 주소</div>
            <div className='rowValue'>{this.props.card.address}</div>
          </div>
          <div className='infoRow'>
            <div className='rowTitle'>대지영역 면적</div>
            <div className='rowValue'>{new Intl.NumberFormat(undefined, {maximumFractionDigits: 2}).format(this.props.card.project_site_area)}㎡</div>
          </div>
          <div className='infoRow'>
            <div className='rowTitle'>특수영역 면적</div>
            <div className='rowValue'>{new Intl.NumberFormat(undefined, {maximumFractionDigits: 2}).format(this.props.card.vacancy_inside_area)}㎡</div>
          </div>
          <div className='infoRow'>
            <div className='rowTitle'>생성일</div>
            <div className='rowValue'>{moment(this.props.card.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
          </div>
        </div>
        break;
      case pageContent.myPlan:
        infoTable = <div className='inofTable'>
          <div className='infoRow'>
            <div className='rowTitle'>주소</div>
            <div className='rowValue'>{this.props.card.address}</div>
          </div>
          <div className='infoRow'>
            <div className='rowTitle'>대지영역 면적</div>
            <div className='rowValue'>{new Intl.NumberFormat(undefined, {maximumFractionDigits: 2}).format(this.props.card.project_site_area)}㎡</div>
          </div>
          <div className='infoRow'>
            <div className='rowTitle'>특수영역 면적</div>
            <div className='rowValue'>{new Intl.NumberFormat(undefined, {maximumFractionDigits: 2}).format(this.props.card.vacancy_inside_area)}㎡</div>
          </div>
          <div className='infoRow'>
            <div className='rowTitle'>용적율</div>
            <div className='rowValue'>{new Intl.NumberFormat(undefined, {maximumFractionDigits: 2}).format(this.props.card.total_floor_area / this.props.card.project_site_area * 100)}%</div>
          </div>
          <div className='infoRow'>
            <div className='rowTitle'>생성일</div>
            <div className='rowValue'>{moment(this.props.card.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
          </div>
        </div>
        break;
      case pageContent.myBuildingType:
        infoTable = <div className='inofTable'>
          <div className='infoRow'>
            <div className='rowTitle'>세대수</div>
            <div className='rowValue'>{this.props.card.houses_number} 세대</div>
          </div>
          <div className='infoRow'>
            <div className='rowTitle'>평균전용 면적</div>
            <div className='rowValue'>{new Intl.NumberFormat(undefined, {maximumFractionDigits: 2}).format(this.props.card.total_exclusive_area / this.props.card.houses_number)}㎡</div>
          </div>
          <div className='infoRow'>
            <div className='rowTitle'>생성일</div>
            <div className='rowValue'>{moment(this.props.card.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
          </div>
        </div>
        break;
      default:
        break;
    }
    return (
      <div className='card' style={{ marginRight: this.props.margin }}>
        <div className="header">
          <Checkbox disableRipple={true} 
          className={`titleCheck checkbox ${this.props.checked && "checked" || ""}`}
          checked={this.props.checked}
          onClick={e => {
            this.props.setCheck(this.props.card.global_id)
          }}>          
          </Checkbox>
          <div className='title font bg-navy font-primary font-14px font-roboto'>{this.props.card.user_id}</div>
        </div>
        <div onMouseOver={e => this.setState({isImageHover: true})} onMouseLeave={e => this.setState({isImageHover: false})}>
          <Fragment>
            {
              this.state.isImageHover &&
              <div className="over-img">
                <Tooltip msg="DXF파일 내려받기" className="button start">
                  <Button className="button start btn bg-navy btn-primary" disableRipple={true}
                  onClick={this.downloadFile}>
                    <DownloadIcon/>
                  </Button>
                </Tooltip>
                <Tooltip msg="삭제" className="button end">
                  <Button className="button end btn bg-navy btn-cancel" disableRipple={true}
                  onClick={this.delteFile}>
                    <DeleteIcon/>
                  </Button>
                </Tooltip>
              </div>
            }
          </Fragment>
          <S3Image
            props={{
              className: "img",
            }}
            s3={{
              Bucket: this.props.S3BucketName,
              Key: (URI.parse(this.props.card.img_path, { absolutePath: true }).path as string).substring(1),
            }}
          />
        </div>
        <div className='info'>
          <div className='nameTag'>
            {
              this.state.focusOnNameTag &&
                <BuilditInput
                  onClickAway={this.nameOnBlur} type='text' 
                  className={`nameEdit font font-roboto font-14px ${this.state.focusOnNameTag && "focus" || ""}`}
                  onChange={(e) => this.nameChanged(e as string)} 
                  value={this.state.name}
                  onKeyUp={e => {
                    if (e.keyCode === 13) {
                      this.nameOnBlur();
                    }
                  }}>
                </BuilditInput>
              ||
              <Fragment>
                <div className="nameNoEdit" onClick={() => this.setState({ focusOnNameTag: true })} title={this.state.name} >
                  <div className="nameArea font bg-navy font-emphasis font-roboto font-14px">{this.state.name}</div>
                </div>
              </Fragment>
            }
            
          </div>
          <div className='cardContent'>
            {infoTable}
          </div>
        </div>
      </div>
    )
  }
}