import React, { Component } from 'react';
import FolderOpenOutlined from '@material-ui/icons/FolderOpenOutlined';
import Refresh from '@material-ui/icons/Refresh';
import Settings from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CheckIcon from '@material-ui/icons/Check'
import ExpandMore from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import '../css/CADConverter/ConverterHeader.scss';
import { Button } from '@material-ui/core';
import { ConverterType } from './DataTypes';

interface HeadProps {
  type: ConverterType,
  email: string,
  errorSize: number,
  warningSize: number,
  loadFile: (e: React.ChangeEvent<HTMLInputElement>) => void,
  saveFile: () => void,
  reset: () => void,
  showErrorLog?: () => void,
  openSettingModal: () => void,
}

interface HeadState {
  showChangePageBox: boolean,
}

export class ConverterHeader extends Component<HeadProps, HeadState> {
  state: HeadState = {
    showChangePageBox: false,
  }

  render = () => {
    return (
      <header>
        <div className='Head'>
          <div className='logoDiv' onClick={() => window.open("/", "_self")} >
            <img src="/img/buildit_platform_logo.svg" className="logoImg" />
          </div>
          <div className='dropdownButton' >
            <div className='mainText' onClick={() => this.setState({ showChangePageBox: !this.state.showChangePageBox })}>
              <span className='name'>CAD Converter</span>
              <span className='typeName'>{this.props.type}</span>
              <ExpandMore className={`arrowDown ${this.state.showChangePageBox && 'rotate'}`} />
            </div>
            <div className={`dropbox ${this.state.showChangePageBox && 'show'}`}>
              <div className='cell' onClick={e => window.open("/cad/MySite", "_self")}>
                <CheckIcon className={`checkIcon ${this.props.type !== ConverterType.mySite && 'hidden'}`} />
                <span className='name'>CAD Converter</span><span className='typeName'>{ConverterType.mySite}</span>
              </div>
              <div className='cell' onClick={e => window.open("/cad/MyBuildingType", "_self")}>
                <CheckIcon className={`checkIcon ${this.props.type !== ConverterType.myType && 'hidden'}`} />
                <span className='name'>CAD Converter</span><span className='typeName'>{ConverterType.myType}</span>
              </div>
              <div className='cell' onClick={e => window.open("/cad/MyBuildingPlan", "_self")}>
                <CheckIcon className={`checkIcon ${this.props.type !== ConverterType.myPlane && 'hidden'}`} />
                <span className='name'>CAD Converter</span><span className='typeName'>{ConverterType.myPlane}</span>
              </div>
            </div>
          </div>
          <div className='Tools'>
            <div className={`errorMessage`} onClick={this.props.showErrorLog}>
              <span className={`errorInfo ${this.props.errorSize === 0 && 'hidden'}`}><ErrorIcon className={`errorIcon icon`} /><span className='value'>{this.props.errorSize}</span></span>
              <span className={`errorInfo ${this.props.warningSize === 0 && 'hidden'}`}><WarningIcon className={`warningIcon icon`} /><span className='value'>{this.props.warningSize}</span></span>
            </div>
            <div className='account tooltip'>
              <span className="tooltiptext">{this.props.email}</span>
              <AccountCircle className='icon' />
            </div>
            <label className='imageBox tooltip' >
              <input type="file" id="ex_file" style={{ display: 'none' }} onChange={this.props.loadFile} onClick={e=>e.currentTarget.value=''} accept='.dxf' />
              <span className="tooltiptext">불러오기</span>
              <FolderOpenOutlined className='imageButton' />
            </label>
            <div className='imageBox tooltip' onClick={this.props.reset}>
              <span className="tooltiptext">리셋</span>
              <Refresh className='imageButton' />
            </div>
            <div className='imageBox tooltip' onClick={this.props.openSettingModal}>
              <span className="tooltiptext">설정</span>
              <Settings className='imageButton' />
            </div>

            <Button className='saveButton' onClick={this.props.saveFile}>
              {this.props.type} 저장
            </Button>
          </div>
        </div>
      </header>
    )
  }
}