import * as THREE from '@teneleven/three';
import { ConverterLayer, mapProjectionData, screenInfo } from './DataTypes';
import { getFieldArea, switchLayerState } from './CoreAndHouseController';
import { NaverPoint } from './NaverMapManager';
import { GeotoWKT } from './SceneManager';
const uuid4 = require('uuid/v4');

export enum FieldType {
  site = '대지영역',
  road = '도로영역',
  vacancyOutside = '공지영역',
  vacancyInside = '특수영역',
}

//data controller
export class Field {
  name: string;
  typeName: FieldType;
  private layer: ConverterLayer | null;
  singlePolygon: boolean;
  private area: number;
  id: string;

  constructor(name: string, fieldType: FieldType) {
    this.name = fieldType + ' ' + name;
    this.typeName = fieldType;
    this.layer = null;
    this.singlePolygon = fieldType === FieldType.site ? true : false;
    this.area = 0;
    this.id = uuid4();
  }

  setLayer = (layer: ConverterLayer | null) => {
    let exLayer = this.layer;
    this.layer = layer;

    if (exLayer)
      switchLayerState(exLayer);

    switchLayerState(layer);

    this.area = getFieldArea(this);
  }

  getFieldLayerName = () => {
    if (this.layer)
      return this.layer.name;
    else
      return null;
  }

  getLayer = () => { return this.layer }

  setArea = (area: number) => {
    this.area = area;
  }

  getArea = () => { return this.area; }

  private getLatLonPosition(point: THREE.Vector3, mapProjData: mapProjectionData, screenInfo: screenInfo) {
    let np = point.clone().applyMatrix4(screenInfo.mvp);
    let nx = (np.x + 1) * 0.5 * screenInfo.rendererSize.x;
    let ny = (1 - (np.y + 1) * 0.5) * screenInfo.rendererSize.y;
    return mapProjData.projection.fromPageXYToCoord(NaverPoint(nx + screenInfo.offset.left, ny + screenInfo.offset.top));
  }

  getFieldLatLngs = (matrix: THREE.Matrix4, mapProj: mapProjectionData, screenInfo: screenInfo) => {
    let latlngs: any[] = [];
    if (this.layer) {
      this.layer.polygons.forEach(basePolygon => {
        if (basePolygon.selected && !basePolygon.motherPolygon) {
          let bodyPolygon: any[] = [];
          let polygon: any[] = [];
          basePolygon.vertices.forEach(v => {
            let pos = this.getLatLonPosition(v.clone().applyMatrix4(matrix), mapProj, screenInfo);
            bodyPolygon.push([pos.x + mapProj.mapOffset.x, pos.y + mapProj.mapOffset.y]);
          })

          polygon.push(bodyPolygon);
          this.layer!.polygons.forEach(holePolygon => {
            if (holePolygon.motherPolygon === basePolygon) {
              let hole: any[] = [];
              holePolygon.vertices.forEach(v => {
                let pos = this.getLatLonPosition(v.clone().applyMatrix4(matrix), mapProj, screenInfo);
                hole.push([pos.x + mapProj.mapOffset.x, pos.y + mapProj.mapOffset.y]);
              })
              polygon.push(hole);
            }
          })
          latlngs = latlngs.concat(polygon);
        }
      })
    }

    return latlngs;
  }

  getWKTArray = (matrix: THREE.Matrix4, mapProj: mapProjectionData, screenInfo: screenInfo) => {
    let polygonWKTArray: string[] = [];

    let geoJSON = {
      type: 'Polygon',
      coordinates: this.getFieldLatLngs(matrix, mapProj, screenInfo),
    }

    polygonWKTArray.push(GeotoWKT(geoJSON));

    return polygonWKTArray;
  }

  getFirstPoint = () => {
    if (this.layer) {
      return this.layer.polygons[0].vertices[0];
    }
  }
}
