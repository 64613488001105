import React, { Component, Fragment } from 'react';
import SessionComponent from './SessionComponent';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, CircularProgress } from '@material-ui/core';

import './css/BuilditTemplate.scss';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PageFoot from './pageFoot';
import App from './App';
import { ReferenceObject } from 'popper.js';
import BuilditInput from './BuilditInput';

export interface BuilditTemplateProps {
  header?: boolean;
  footer?: boolean;
  loading?: boolean;
  loadingContent?: JSX.Element | string;
}
export interface BuilditTemplateState {
  openMenu: boolean;
}
export default class BuilditTemplate extends Component<BuilditTemplateProps, BuilditTemplateState> {
  state: BuilditTemplateState = {
    openMenu: false,
  }
  anchorEl?: null | HTMLElement | ReferenceObject | ((element: HTMLElement) => HTMLElement);
  render = () => {
    return (
      <div className="buildit-template"  style={{overflow: this.props.loading && "hidden" || "auto"}}>
        {
          (this.props.header === true || this.props.header === undefined) &&
          <AppBar
            className="app-bar"
            position="fixed"
            color="inherit"
          >
            <Toolbar className="tool-bar">
              <Link to="/" title={App.stage === 'dev' ? process.env.REACT_APP_SPRINT : ''}>
                <img src="/img/buildit_platform_logo.svg" style={{height: "25px"}} />
              </Link>
              <div className="menus">
                <Link to={ {pathname: "/project/list", } } >

                </Link>
                <Link to="/project/list" className="text-deco-none" >
                  <div className="bg-navy font font-primary items">프로젝트 목록</div>
                </Link>
                
                <Button onClick={e => this.setState({ openMenu: !this.state.openMenu })}
                  style={{padding: "0px"}}
                  disableRipple={true}
                  className="my-page-btn"
                >
                  <div className="font-secondary email-wrap">
                    <div className="bg-navy font font-primary font-roboto font-noto email">{App.session.email}</div>
                    {
                      this.state.openMenu &&
                      <KeyboardArrowUpIcon className="icon "/> ||
                      <KeyboardArrowDownIcon className="icon" />
                    }
                  </div>
                  {
                    this.state.openMenu &&
                    <ClickAwayListener onClickAway={() => this.setState({ openMenu: false })}>
                      <div className="select-menu">
                        <Link to="/myPage/file" className="text-deco-none wrap">
                          <div className="menu">파일 관리</div>
                        </Link>
                        <Link to="/myPage/point" className="text-deco-none wrap">
                          <div className="menu">구매/포인트 내역</div>
                        </Link>
                        <Link to="/myPage/information" className="text-deco-none wrap">
                          <div className="menu">계정 관리</div>
                        </Link>
                        <div className="wrap" onClick={() => App.logout()}>
                          <div className="menu">로그아웃</div>
                        </div>
                      </div>

                    </ClickAwayListener>
                  }
                </Button>
                <Link to="/designerdownload" className="text-deco-none">
                  <div className="bg-navy font font-primary items">DOWNLOAD</div>
                </Link>
                <Link to="/customerSupport" className="text-deco-none">
                  <div className="bg-navy font font-primary items">고객지원&FAQ</div>
                </Link>
                <Link to="/project/create" className="text-deco-none">
                  <div className="bg-navy font font-special display-flex" style={{paddingRight: "30px"}}>
                    <Button className="new-project-btn bg-navy btn btn-run btn-large">+ 새 프로젝트 시작</Button>
                  </div>
                </Link>
                <a href="https://d-md.buildit.co.kr/" className="text-deco-none">
                  <div className="bg-navy font font-special display-flex" style={{paddingRight: "30px"}}>
                    <Button className="new-project-btn bg-navy btn btn-run btn-large">+ d-md로 가기</Button>
                  </div>
                </a>
                {
                }
                {/* <Link className="text-deco-none" 
                  to={
                    { 
                      pathname: "/project/create", 
                    }
                  }
                >
                  <div className="bg-navy font font-special display-flex" style={{paddingRight: "30px"}}
                >
                    <Button className="new-project-btn bg-navy btn btn-run btn-large">+ 새 프로젝트 시작</Button>
                  </div>
                </Link> */}
              </div>
            </Toolbar>
          </AppBar>

        }
        <main className="template-content">
          {
            (this.props.header === true || this.props.header === undefined) && <div className="app-bar-space" />
          }
          <div className="main-content">
            {
              this.props.children
            }
            {
              this.props.footer &&
              <Fragment>
              <PageFoot />
              </Fragment>
            }
          </div>
          {
            this.props.loading && 
            <div className="dim">
              <CircularProgress className="circular-progress" />
              <div className="content">
              {
                this.props.loadingContent
              }
              </div>
            </div>
          }
        </main>
      </div>
    );
  } 
}

