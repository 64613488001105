import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import { Button } from '@material-ui/core';
import { ConverterInputBox } from './ConverterInputBox';
import { ConverterLayer } from './DataTypes';
import { Field, FieldType } from './Field';
import '../css/CADConverter/HouseAndCore.scss';

interface fieldProp {
  field: Field,
  selectLayer: ConverterLayer | null,
  settingID: string,
  showLayerList: (id: string) => void,
  removeField: (field: Field) => void,
  valueChanged: () => void,
}

interface fieldState {
  layerName: string | null,
  id: string,
  name: string
  value: number,
}
// user interface
export class FieldUI extends Component<fieldProp, fieldState> {
  state: fieldState = {
    layerName: this.props.field.getFieldLayerName(),
    name: this.props.field.name,
    id: this.props.field.id,
    value: this.props.field.getArea(),
  }

  componentDidMount = async () => {

  }

  componentDidUpdate = (previousProps: Readonly<fieldProp>, previousState: Readonly<fieldState>) => {
    if (this.props.settingID !== this.props.field.id) {
      return;
    }

    if (this.props.selectLayer !== previousProps.selectLayer) {
      this.props.field.setLayer(this.props.selectLayer);
      this.setState({
        layerName: this.props.field.getFieldLayerName(),
        value: this.props.field.getArea()
      });
    }
  }

  //to-do: 저장된 면적이 입력한 면작 인지 확니 필요
  valueChanged = (value: number) => {
    this.props.field.setArea(value);
    this.props.valueChanged();
    this.setState({ value: value })
  }

  render = () => {
    return (
      <div className='field' key={this.state.id}>
        <div className='nameDiv'>{this.state.name}<CloseIcon className='closeIcon' onClick={e => this.props.removeField(this.props.field)} /></div>
        <div className='buttonDiv'>
          <div className={`selectButton ${this.props.settingID === this.props.field.id && 'selected' || 'unselected'}`} onClick={e => this.props.showLayerList(this.props.field.id)} >
            <span className='text'>{this.props.field.typeName} 선택</span>
            <CheckIcon className={`CheckIcon ${this.state.layerName && 'checked'}`} />
          </div>
          <div className='nameText'>
            {this.state.layerName && this.state.layerName}
            {/* <CloseIcon className={`closeIcon ${(this.props.settingID === this.props.field.id && this.state.layerName) && 'selected' || 'unselected'}`} onClick={this.deleteWall} /> */}
          </div>
        </div>
        <div className={`inputValues ${(this.props.field.typeName === FieldType.road || this.props.field.typeName === FieldType.vacancyOutside) && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
          <ConverterInputBox name='면적' value={this.state.value} valueOnChange={this.valueChanged} unit='㎡' step={0.01} />
        </div>
        <div className='Line' />
      </div>
    )
  }
}
