import React, { Component } from 'react';
import Close from '@material-ui/icons/Close'
import { ConverterLayer, ListType, PartOfHouse } from './DataTypes';
import { brightenAllLayer, darkenAllLayer } from './CoreAndHouseController';
import Check from '@material-ui/icons/Check'
import '../css/CADConverter/LayerPanel.scss';
import { House } from './House';

interface layerPanelProp {
  showlayerPanle: boolean,
  layers: ConverterLayer[],
  houses: House[],
  listType: ListType,
  settingName: string,
  partOfHouse: PartOfHouse,
  currentSelected: ConverterLayer | House[] | null,
  closePanel: () => void,
  setLayer: (layer: ConverterLayer) => void,
  setHouse: (house: House) => void,
}

interface layerPanelState {
  listType: ListType,
}

export class LayerPanel extends Component<layerPanelProp, layerPanelState>{
  state: layerPanelState = {
    listType: this.props.listType
  }

  componentDidUpdate = (previousProps: Readonly<layerPanelProp>, previousState: Readonly<layerPanelState>) => {
    if (previousProps.listType !== this.props.listType) {
      this.setState({ listType: this.props.listType })
    }
  }

  getInformation = () => {
    let connect = ''
    switch (this.props.partOfHouse) {
      case PartOfHouse.house:
        connect = '연결된 세대를'
        break;
      case PartOfHouse.core:
        connect = '코어 레이어를'
        break;
      case PartOfHouse.lightWindow:
        connect = '채광 창 레이어을'
        break;
      case PartOfHouse.normalWindow:
        connect = '일반 창 레이어을'
        break;
      case PartOfHouse.wall:
        connect = '벽 레이어를'
        break;
    }

    return `${this.props.settingName}의 ${connect} 선택해주세요.`;
  }

  //to-do: need rebuild
  checkCurrentSelectedLayer = (layer: ConverterLayer) => {
    let checked = false;
    if (this.props.currentSelected) {
      if ((this.props.currentSelected as ConverterLayer).name === layer.name)
        checked = true;
    }

    return checked;
  }

  //to-do: need rebuild
  checkCurrentSelectedHouse = (house: House) => {
    let checked = false;
    if (this.props.currentSelected) {
      try {
        if ((this.props.currentSelected as House[]).find(h => h.id === house.id))
          checked = true;
      } catch (error) {

      }
    }

    return checked;
  }

  render = () => {
    return (
      <div className='myTypeLayerLayout' hidden={this.props.showlayerPanle}>
        <div className='arrowDiv'><span className='text'>레이어</span><Close className='closeIcon' onClick={this.props.closePanel} /></div>
        <div className='switchTabs'>
          <div className={`switchButton ${this.state.listType === ListType.layer && 'enable'}`} onClick={() => this.setState({ listType: ListType.layer })}><span className='text'>CAD 레이어</span></div>
          <div className={`switchButton ${this.state.listType === ListType.house && 'enable'}`} onClick={() => this.setState({ listType: ListType.house })}><span className='text'>세대</span></div>
        </div>
        <div className={`description ${this.props.settingName === '' && 'hidden'}`}>{this.getInformation()}</div>
        <div className='layerList' onMouseLeave={() => brightenAllLayer(this.props.layers)}>
          {(this.state.listType === ListType.layer &&
            <div>
              {this.props.layers!.map(l =>
                <div key={l.name} className={`listButton ${l.errorLayer && 'error' || ''} ${this.checkCurrentSelectedLayer(l) && 'selected' || ''}`} onMouseOver={() => { darkenAllLayer(this.props.layers); brightenAllLayer([l]); }} onClick={() => this.props.setLayer(l)}>
                  <Close className={`CheckIcon close`} />
                  <Check className={`CheckIcon check ${l.selected && 'selected'} ${l.errorLayer && 'error'}`} />
                  <span className={`text ${l.errorLayer && 'error'}`}>{`${l.name}`}</span>
                </div>
              )}
            </div>) ||
            <div>
              {this.props.houses.map(h =>
                <div className={`listButton ${this.checkCurrentSelectedHouse(h) && 'selected'}`} key={h.name} onMouseOver={() => { darkenAllLayer(this.props.layers); brightenAllLayer(h.getSettedLayer()); }} onClick={() => this.props.setHouse(h)}>
                  <Close className={`CheckIcon close`} />
                  <Check className={`CheckIcon check ${h.selected && 'selected'}`} />
                  <span className='text'>{`${h.name}`}</span>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    )
  }
}