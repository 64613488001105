import AWS from 'aws-sdk';
import * as THREE from '@teneleven/three';
import * as turf from '@turf/turf';
import App from '../App';
import _ from 'lodash';
import AWSModule from '../AWSModule';

import { PolylineInfo, SiteType, DEMData } from './DataTypes';
import { WKTtoGeom, GeotoWKT } from './SceneManager';
import { building, buildingData, areaInfo, areaInfoSet, polyline, lineInfo, floorInfo, floorStatus } from './resultLocationDataStruct';
import { Core } from './Core';

export async function getDEM(TMx: number, TMy: number) {
  let s3 = await new AWSModule("S3").connect();

  const cx = (Math.floor(TMx / 2560) - 1) * 2560;
  const cy = (Math.floor(TMy / 2560) - 1) * 2560 + 1120 + 2560;
  // const key = cx + '_' + cy;

  const dems = [[cx, cy]];
  let offset = 1;
  for (let i = -offset; i <= offset; i += 1) {
    for (let j = -offset; j <= offset; j += 1) {
      if (i === 0 && j === 0) {
        continue;
      }
      dems.push([dems[0][0] + 2560 * i, dems[0][1] + 2560 * j])
    }
  }

  let demValues: DEMData[] = [];
  await new Promise(resolve => {
    dems.forEach(async key => {
      const sr = await s3.S3!.getObject({
        Bucket: 'teneleven-pubdata-dem-v2-apn2',
        Key: `${key[0] + '_' + key[1]}`,
      }).promise();
      const vals = _.range(257).map(i => {
        return _.range(257).map(j => (sr.Body as Buffer).readInt16LE((i * 257 + j) * 2))
      });
      demValues.push({ leftBottom: new THREE.Vector2(key[0], key[1]), values: vals });
    });
    resolve();
  })
  return demValues;
}

export async function getSaveData() {
  let s3 = await new AWSModule("S3").connect();

  const sr = await s3.S3!.getObject({
    Bucket: 'teneleven-platform-my-building-type-v2',
    Key: `dev/159/27a0edf6-90e4-4845-90f4-4fa0f2145e2a.json`,
  }).promise();

  App.stage !== "prod" && console.log(sr.Body!.toString());
}

export async function getAddressByProjectSite(projectSite: string[] | any) {
  const r = await App.API_Axios.post('/ProjectDefault', {
    project_site: projectSite,
  }, {
    headers: {
      ...App.headers,
    },
  });

  return r.data['주소'];
}

export async function getRoadLine(site: string[]) {
  const lambda = await new AWSModule("LAMBDA").connect();
  const r = await lambda.Lambda!.invoke({
    FunctionName: "engine-roadline-generator-latest",
    Payload: JSON.stringify(
      {
        "isRoad": "true",
        "polySet": site,
        "genType": "USERDEF",
      }
    )
  }).promise();

  return JSON.parse(r.Payload as string)['body'];
}

export async function saveDataToS3(data: string | Buffer | Uint8Array | Blob | undefined, bucket: string, fileName: string, contentType: string) {
  let s3 = await new AWSModule("S3").connect();

  await s3.S3!.putObject({
    Bucket: bucket,
    Key: fileName,
    Body: data,
    ContentType: contentType,
  }).promise();
}

export async function saveDataToDynamoDB(data: any, tableName: string) {
  let ddb = await new AWSModule("DDB").connect();

  await ddb.Ddb!.put({
    TableName: tableName,
    Item: data,
  }).promise();
}

export async function updateDynamoDBData(dbTable: string, item: AWS.DynamoDB.DocumentClient.AttributeMap) {
  const lambda = await new AWSModule("LAMBDA").connect();

  await lambda.Lambda!.invoke({
    FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
    Payload: JSON.stringify({
      TableName: dbTable,
      Item: item,
    })
  }).promise();
}

export async function getSettingData() {
  let ddb = await new AWSModule("DDB").connect();

  let dbData = await ddb.Ddb!.get({
    TableName: 'platform-buildit-cad-converter-user-setting',
    Key: {
      stage: App.stage,
      email: App.session.email,
    },
  }).promise();

  return dbData.Item
}

export function checkDuplicatePointAndAdd(nodes: THREE.Vector2[], node: THREE.Vector2) {
  let exist = false;
  nodes.forEach(n => {
    if (new THREE.Vector2(n.x, n.y).distanceTo(new THREE.Vector2(node.x, node.y)) < 0.0001) {
      exist = true;
    }
  });

  if (!exist) {
    nodes.push(new THREE.Vector2(Number(node.x.toFixed(4)), Number(node.y.toFixed(4))));
  }
}

export function pushAllPoints(nodes: THREE.Vector2[], polygon: PolylineInfo[], basePosition: THREE.Vector3) {
  for (let i = 0; i < polygon.length; i++) {
    let startPoint = polygon[i].line.start.clone().sub(basePosition);
    let endPoint = polygon[i].line.end.clone().sub(basePosition);

    checkDuplicatePointAndAdd(nodes, new THREE.Vector2(startPoint.x, startPoint.y))
    checkDuplicatePointAndAdd(nodes, new THREE.Vector2(endPoint.x, endPoint.y))
  }
}

export function findVerticeIndex(nodes: THREE.Vector2[], node: THREE.Vector2) {
  let id = -1;
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].distanceTo(node) < 0.01) {
      id = i;
    }
  }
  if (id === -1) {
    App.stage !== "prod" && console.log(nodes, node);
  }
  return id;
}

export function getLineInfo(nodes: THREE.Vector2[], lines: PolylineInfo[], minPos: THREE.Vector3) {
  let outputLines: lineInfo[] = [];

  for (let i = 0; i < lines.length; i++) {
    let startPoint = lines[i].line.start.clone().sub(minPos);
    let endPoint = lines[i].line.end.clone().sub(minPos);
    if (startPoint.distanceTo(endPoint) > 0.0001) {
      let startIndex = findVerticeIndex(nodes, new THREE.Vector2(startPoint.x, startPoint.y));
      let endIndex = findVerticeIndex(nodes, new THREE.Vector2(endPoint.x, endPoint.y));

      outputLines.push({
        start: startIndex,
        end: endIndex,
        lineType: lines[i].type,
        thickness: lines[i].thickness,
      })
    }
  }
  return outputLines;
}

export function getBuildingData(cores: Core[]) {
  let coreTotal: number[] = [];
  let houseTotal: number[] = [];
  let areaInfoSet: areaInfoSet[] = [];
  let outline: polyline[] = [];
  let maxLevel: number = 0;
  let floorStatus: floorStatus[] = [];

  let bbox = new THREE.Box3();
  cores.forEach(c => {
    coreTotal.push(1);
    houseTotal.push(c.houses.length);

    c.outputPolygon.forEach(p => {
      bbox.expandByPoint(p.line.start);
      bbox.expandByPoint(p.line.end);
    });

    c.houses.forEach(h => {
      h.outputPolygon.forEach(p => {
        bbox.expandByPoint(p.line.start);
        bbox.expandByPoint(p.line.end);
      })
    });
  })

  let minPos = bbox.min.clone();
  // let nodes: THREE.Vector2[] = [];
  let group = 0;
  let coreIndex = 0;
  cores.forEach(c => {
    let coreNodes: THREE.Vector2[] = [];
    let coreLines: lineInfo[] = [];
    let coreLevel = 0;
    let houseIndex = 0;
    let areaInfoArray: areaInfo[] = []
    let coreFloorinfo: floorInfo[] = [];
    let houseFloorinfo: floorInfo[] = [];

    let areaInfo: areaInfo = {
      areaOfReference: -1,
      areaOfArchitecture: -1,
      areaFloor: -1,
      areaOfExclusive: -1,
      areaOfInner: -1,
      areaOfCorepart: c.area,
      areaOfService: -1,
      areaOfSupply: -1,
      areaOfCostEstimation: -1,
      areaOfCommonETC: -1,
      areaOfContract: -1,
      areaOfPayingout: -1,
      bayNum: 0
    }

    pushAllPoints(coreNodes, c.outputPolygon, minPos);
    coreLines = getLineInfo(coreNodes, c.outputPolygon, minPos);
    areaInfoArray.push(areaInfo);

    let polyline: polyline = {
      category: "PCT_CORE",
      type: 'PT_OUTLINE',
      group: group,
      categoryIndex: coreIndex,
      componentIndex: coreIndex,
      node: { data: coreNodes },
      areaInfo: areaInfo,
      lineInfo: coreLines,
    }
    outline.push(polyline);

    c.houses.forEach(h => {
      let houseNodes: THREE.Vector2[] = [];
      let houseLines: lineInfo[] = [];

      let areaInfo: areaInfo = {
        areaOfReference: Math.round(h.exclusiveArea / 5) * 5,
        areaOfArchitecture: h.exclusiveArea + h.serviceArea + c.area / c.houses.length,
        areaFloor: h.exclusiveArea + c.area / c.houses.length,
        areaOfExclusive: h.exclusiveArea,
        areaOfInner: 1,
        areaOfCorepart: c.area / c.houses.length,
        areaOfService: h.serviceArea,
        areaOfSupply: h.exclusiveArea + c.area / c.houses.length,
        areaOfCostEstimation: h.exclusiveArea + h.serviceArea + c.area / c.houses.length,
        areaOfCommonETC: 0,
        areaOfContract: 0,
        areaOfPayingout: h.exclusiveArea + c.area / c.houses.length,
        bayNum: 0
      }

      pushAllPoints(houseNodes, h.outputPolygon, minPos);
      houseLines = getLineInfo(houseNodes, h.outputPolygon, minPos);

      let floorInfo: floorInfo = { floorCategory: [] };
      for (let i = 0; i < h.level.length; i++) {
        if (!h.level[i]) {
          floorInfo.floorCategory.push('FC_PILOTI');
        }
        // else if (!h.level[i]) {
        //   floorInfo.floorCategory.push('FC_VOID');
        // }
        else {
          floorInfo.floorCategory.push('FC_HOUSE');
        }
      }
      houseFloorinfo.push(floorInfo);
      coreLevel = coreLevel < h.level.length ? h.level.length : coreLevel;
      areaInfoArray.push(areaInfo);
      let polyline: polyline = {
        category: "PCT_HOUSE",
        type: 'PT_OUTLINE',
        group: group,
        categoryIndex: houseIndex,
        componentIndex: houseIndex,
        node: { data: houseNodes },
        areaInfo: areaInfo,
        lineInfo: houseLines,
      }

      outline.push(polyline);
      houseIndex++;
    })

    let floorInfo: floorInfo = { floorCategory: [] };
    for (let i = 0; i < coreLevel; i++) {
      floorInfo.floorCategory.push('FC_CORE');
    }
    coreFloorinfo.push(floorInfo);
    maxLevel = coreLevel < maxLevel ? maxLevel : coreLevel;
    areaInfoSet.push({ areaInfo: areaInfoArray });

    floorStatus.push({
      coreFloorInfo: coreFloorinfo,
      houseFloorInfo: houseFloorinfo,
    })
    group++;
  })

  // template 
  let building: building = {
    angleRange: { min: -90, max: 90 },
    areaInfoSet: areaInfoSet,
    groupTotal: cores.length,
    coreTotal: coreTotal,
    houseTotal: houseTotal,
    floorThickness: 0,
    isUserData: true,
    name: 'building',
    outline: outline,
    polylineFull: outline,
  }

  let floorHeights: number[] = [];
  for (let i = 0; i < maxLevel; i++) {
    floorHeights.push(2.8);
  }

  // building
  let buildingData: buildingData = {
    angle: 0,
    building: building,
    position: minPos,
    rotStatus: 'DEFAULT',
    initialCondition: false,
    rotationOrient: new THREE.Vector2(0, 0),
    floorStatus: floorStatus,// [{ coreFloorInfo: coreFloorinfo, houseFloorInfo: houseFloorinfo }],
    floorHeight: floorHeights,
    name: building.name,
    setbackRegulationFromNorthLess9m: 1.5,
    setbackRegulationFromNorthLess9mType: 'METER',
    setbackRegulationFromNorthMore9m: 0.5,
    setbackRegulationFromNorthMore9mType: 'HEIGHT',
    setbackRegulationFromSiteBoundary: 0.5,
    distanceBetweenWindowOpaqueWalls: 0.8,
    distanceBetweenSideOpaqueWalls: 8,
    distanceBetweenSideWalls: 8,
  }
  return buildingData;
}

export function polygonBufferWithDistance(polygons: any[], siteType: SiteType) {
  let boundaryLines: any[] = [];
  let distance = -4;

  switch (siteType) {
    case SiteType.Apartment:
      distance = -4;
      break;
    case SiteType.Officetel:
      distance = -4;
      break;
    default:
      break;
  }

  polygons.forEach(wkt => {
    const gj = WKTtoGeom(wkt);
    const p = {
      "type": "Feature",
      "geometry": gj,
    } as any;

    const b = turf.buffer(p, distance, { units: "meters" });

    boundaryLines.push(GeotoWKT(b.geometry));
  });
  return boundaryLines;
}

export async function checkFileName(fileName: string, userID: string, tableName: string) {
  let r = await App.search({
    "table": tableName,
    "query": {
      "query_string": {
        "query": `stage.keyword:${App.stage} AND user_id.keyword:${userID} AND deleted:false AND name:"${fileName}"`
      }
    }
  });

  return r.data.hits.total > 0 ? false : true;
}

export function checkSpecialSymbolInName(name: string) {
  let hasSpecialSymbol = false;
  let sSymbol = [];

  sSymbol.push('"');
  sSymbol.push('/');
  sSymbol.push('\\');

  sSymbol.forEach(s => {
    if (!hasSpecialSymbol)
      hasSpecialSymbol = name.includes(s);
  });

  return hasSpecialSymbol;
}
