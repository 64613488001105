import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Info from '@material-ui/icons/InfoOutlined';
import '../css/CADConverter/SettingModal.scss'
import { ConverterType, Unit } from './DataTypes';
import { saveDataToDynamoDB, updateDynamoDBData, getSettingData } from './DBManager';
import { myTypeSettingData, MyTypeSettingDataUI } from './detialSetting';

interface defaultSettingData {
  autoSetting: boolean,
  dataUnit: Unit,
}

export class userSettingData extends Component implements defaultSettingData {
  static myTypeSettingData: myTypeSettingData;
  autoSetting = true;
  dataUnit = Unit.Millimeters;
  settingDBTable = 'platform-buildit-cad-converter-user-setting';

  componentDidMount = async () => {

  }

  getDataFromDB = async () => {
    let data = await getSettingData();
    if (data) {
      if (!userSettingData.myTypeSettingData)
        userSettingData.myTypeSettingData = new myTypeSettingData();
      userSettingData.myTypeSettingData.updateDataFromDB(data.myType);
      this.autoSetting = data.autoSetting !== undefined ? data.autoSetting : this.autoSetting;
      this.dataUnit = data.dataUnit !== undefined ? data.dataUnit : this.dataUnit;
    }
    else {
      let newData = {
        myType: userSettingData.myTypeSettingData.getData(),
        mySite: {},
        myPlane: {},
        autoSetting: this.autoSetting,
        dataUnit: this.dataUnit,
      }

      saveDataToDynamoDB(newData, this.settingDBTable);
    }
  }

  updateAutoSetting = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.autoSetting = value;
    this.autoSetting = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateUnit = async (value: Unit) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.dataUnit = value;
    this.dataUnit = value;
    updateDynamoDBData(this.settingDBTable, data);
  }
}

export default userSettingData;

interface SettingProps {
  closeModal: () => void,
  settingData: userSettingData,
  open: boolean,
}

interface SettingState {
  unit: Unit,
  showUnit: boolean,
  autoSetting: boolean,
  converterType: ConverterType,
}

export class Setting extends Component<SettingProps, SettingState>{
  state: SettingState = {
    unit: this.props.settingData.dataUnit,
    showUnit: false,
    autoSetting: this.props.settingData.autoSetting,
    converterType: ConverterType.myType
  }

  settingDBTable = 'platform-buildit-cad-converter-user-setting';
  componentDidUpdate = (previousProps: Readonly<SettingProps>, previousState: Readonly<SettingState>) => {
    if (previousProps.open !== this.props.open) {
      this.setState({
        unit: this.props.settingData.dataUnit,
        showUnit: false,
        autoSetting: this.props.settingData.autoSetting,
        converterType: ConverterType.myType
      })
    }
  }

  autoSettingUpdate = (value: boolean) => {
    this.props.settingData.updateAutoSetting(value);
    this.setState({ autoSetting: value })
  }

  unitUpdate = (value: Unit) => {
    this.props.settingData.updateUnit(value);
    this.setState({ showUnit: false, unit: value });
  }

  render = () => {
    return (
      <Dialog className='SettingModalBody' aria-labelledby="simple-dialog-title" open={this.props.open}>
        <div className='title'>
          <span>설정</span>
          <CloseIcon className='closeIcon' onClick={this.props.closeModal} />
        </div>
        <div className='contentTitle'><span>자동 설정</span><Info className='icon' /></div>
        <div className='item'>
          <div className='mianContent'>
            <span className='name'>파일 업로드 시 레이어 자동 설정</span>
            <span className={`text ${!this.state.autoSetting && 'active'}`} style={{ margin: 'auto 0 auto auto' }}>설정 안함</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.autoSetting} onClick={(e) => this.autoSettingUpdate(e.currentTarget.checked)} /> <span className="slider" /> </label>
            <span className={`text ${this.state.autoSetting && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>자동 설정</span>
          </div>
        </div>
        <div className='item'>
          <div className='mianContent'>
            <span className='name' style={{ marginRight: 'auto' }}>캐드 파일 입력 단위</span>
            <div className="unitDropdown">
              <div className="default" onClick={() => this.setState({ showUnit: !this.state.showUnit })}>
                <span className='text'>{this.state.unit}</span> {this.state.showUnit && <ExpandLess className='icon' /> || <ExpandMore className='icon' />}
              </div>
              <div className={`menu ${this.state.showUnit && 'show'}`}>
                <div className="item" onClick={() => this.unitUpdate(Unit.Millimeters)}>{Unit.Millimeters}</div>
                <div className="item" onClick={() => this.unitUpdate(Unit.Meters)}>{Unit.Meters}</div>
                <div className="item" onClick={() => this.unitUpdate(Unit.Inches)}>{Unit.Inches}</div>
              </div>
            </div>
          </div>
        </div>
        <div className='contentTitle'><span>오류 알림 설정(미 완성)</span></div>
        <div className='switchTabs'>
          <div className={`switchButton disable`} ><span className='text'>사업영역</span></div>
          <div className={`switchButton ${this.state.converterType === ConverterType.myType && 'enable'}`} onClick={() => this.setState({ converterType: ConverterType.myType })}><span className='text'>동평면</span></div>
          <div className={`switchButton disable`} ><span className='text'>배치안</span></div>
        </div>
        <MyTypeSettingDataUI myTypeSettingData={userSettingData.myTypeSettingData} />
      </Dialog >
    )
  }
}
