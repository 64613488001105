import React, { Component, Fragment } from 'react';
import RequireIcon from '@material-ui/icons/Lens';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import './css/ProjectFormBasic.scss';
import { Button, Input, Checkbox } from "@material-ui/core";

import ProjectInput, { ProjectFormPropsBase } from './ProjectInput';
import { ConfigType } from './ProjectFormDetail';
import ProjectSelectOptions from './SelectOptions';
import { BuildingType, ProjectUseDistrict } from './model/Project';
import ProjectFieldInput from './ProjectFieldInput';
import Tooltip from './Tooltip';
import { default as _ } from 'lodash';
import { 프로젝트_기본값, 표기_기본값, reset_type } from './ProjectForm';
import BuilditSelect, { List_Element } from './BuilditSelect';
import Modal, { ModalOptions } from './Modal';

/* 
  TODO: 설명 textarea 적용 ( Input multiline )
*/

export interface ProjectFormBasicProps extends ProjectFormPropsBase {
  openJiguInfo: boolean;
  more: boolean;
  setOpen: (open: boolean) => void;
  setMore: (type: ConfigType) => void;
  setOpenJiguInfo: (open: boolean) => void;
  onUpdateDefaults: (set_buildingType?: BuildingType, set_use_distirct?: ProjectUseDistrict, reset?: reset_type) => void;
  isSmallSolverChecked: boolean;
  setSmallSolverRunProject: (smallSovlerChecked: boolean) => void;
  buildingDefault: Map<string, 프로젝트_기본값>;
  initDefault?: 표기_기본값;
}
export interface ProjectFormBasicState {
  openModal: boolean;
  modalOptions?: ModalOptions;
  showDefault?: boolean;
  isSmallSolverChecked: boolean;
}

export default class ProjectFormBasic extends Component<ProjectFormBasicProps, ProjectFormBasicState> {

  state: ProjectFormBasicState = {
    showDefault: this.props.initDefault ? false : undefined,
    openModal: false,
    isSmallSolverChecked: false
  }

  componentDidUpdate = (pp: Readonly<ProjectFormBasicProps>) => {
    console.log('isSmallSolverChecked', this.state.isSmallSolverChecked)
    console.log('props', this.props.isSmallSolverChecked)
  }

  componentDidMount = () => {
    window.addEventListener('pointerup', (e) => {
      this.setState({showDefault: false});
    });
    window.addEventListener('mouseup', (e) => {
      this.setState({showDefault: false});
    });
    this.setSmallSolverChecked(this.props.isSmallSolverChecked)
  }

  setSmallSolverChecked = (check: boolean) => {
    this.setState({
      isSmallSolverChecked: check
    })
  }

  inputMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>{
    if (this.state.showDefault === true && e.nativeEvent.which === 1) {
      this.setState({showDefault: false});
    }
  }

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      openModal: open,
      modalOptions: options
    });
  }

  render () {

    let building_type : {label: string; value: string;}[] | List_Element[] | undefined = ProjectSelectOptions.building_type;
    let available_list : {building_type: string, 허용여부: boolean, 가능여부:boolean}[] = [];
    // if (this.props.currentProject.auto_setting) {
      if (this.props.buildingDefault.size > 0) {
        let temp_list: List_Element[] = [];
        this.props.buildingDefault.forEach((value, key) => {
          if (key === "다세대") {
            temp_list.push({value: "다세대주택", label: `다세대주택`});
            available_list.push({building_type: "다세대주택", 허용여부: value.허용여부!, 가능여부: value.가능여부!});
          } else {
            temp_list.push({value: key, label: `${key}`,});
            available_list.push({building_type: key, 허용여부: value.허용여부!, 가능여부: value.가능여부!});
          }
          building_type = temp_list;
        })
      } 
    // }

    return (
      <Fragment>
        <div className="ProjectFormValue-Header">
          <div className="description">기본값을 수정하시면 컬러가 변경됩니다.</div>
          <Button
            className="show-button"
            disableRipple={true}
            onMouseDown={(e) => {
              if (e.nativeEvent.which === 1 && this.props.initDefault) {
                this.setState({ showDefault: true });
              } else if (this.props.initDefault === undefined) {
                this.setModal(true, {
                  title: "기본값 보기",
                  type: "SIMPLE",
                  positive: () => {
                    this.setModal(false);
                  },
                  negative: "hidden",
                  content: <div>필지를 선택해 주세요</div>,
                });
              }
            }}
          >
            기본값 보기
            {(this.state.showDefault && <VisibilityIcon className="icon active" />) || <VisibilityOffIcon className="icon" />}
          </Button>
        </div>
        <div className="ProjectFormBasic">
          <div>
            <div className="project-field project-address">
              <div className="title">
                프로젝트 주소
                <div
                  className="font font-special jigu-info m-l-a"
                  onClick={() => {
                    this.props.setOpenJiguInfo(!this.props.openJiguInfo);
                  }}
                >
                  토지이용계획 정보 {(this.props.openJiguInfo && "닫기") || "보기"}
                </div>
              </div>
              <div className="content project-address">{this.props.currentProject.project_address || <span style={{ color: "#999" }}>대지영역 설정시 자동입력</span>}</div>
            </div>
            <div className="project-field">
              <ProjectFieldInput
                onMouseDown={this.inputMouseDown}
                showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                defaultValue={this.props.initDefault ? this.props.initDefault.용도지역 : undefined}
                title={`사업영역 용도 지역`}
                fieldName="project_use_district"
                fieldValue={this.props.currentProject.project_use_district}
                className="width-full"
                select={true}
                list={ProjectSelectOptions.project_use_district}
                disabledAutoSetting={true}
                onChange={(value: any) => {
                  // 직접 변경하는경우에
                  this.props.onUpdateProject(
                    {
                      project_use_district: value,
                      building_stories_avg: this.props.currentProject.building_stories_max,
                      building_stories_min: this.props.currentProject.building_stories_max,
                      building_shape_type: [],
                      housing_plan_type: [],
                      my_building_shape_type: [],
                    },
                    () => {
                      // if (this.props.currentProject.auto_setting) {
                      //   this.props.onUpdateDefaults(undefined, value as ProjectUseDistrict);
                      // }
                      this.props.onUpdateDefaults(undefined, value as ProjectUseDistrict, "Value");
                    }
                  );
                }}
                {...this.props}
              />
            </div>
            <div className="small-solver-check-field">
              <Checkbox
                disableRipple={true}
                className={`checkbox m-l-a ${(this.props.isSmallSolverChecked && "checked") || ""}`}
                checked={this.state.isSmallSolverChecked}
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.setSmallSolverRunProject(!this.state.isSmallSolverChecked);
                  this.setSmallSolverChecked(!this.state.isSmallSolverChecked);
                }}
              />
              <span className="small-solver-check-label">소규모솔버에서 돌리기</span>
            </div>

            <div className="project-field">
              {(this.props.currentProject.project_type === "AI" && (
                <Fragment>
                  <ProjectFieldInput
                    onMouseDown={this.inputMouseDown}
                    showDefault={this.props.initDefault ? this.state.showDefault : undefined}
                    defaultValue={this.props.initDefault ? this.props.initDefault.건물종류 : undefined}
                    title={`건물 종류`}
                    fieldName="building_type"
                    fieldValue={this.props.currentProject.building_type}
                    className="width-full"
                    select={true}
                    list={building_type}
                    disabledAutoSetting={true}
                    onChange={(value: any) => {
                      // 직접 변경하는경우에
                      this.props.onUpdateProject(
                        {
                          building_type: value,
                          building_stories_avg: this.props.currentProject.building_stories_max,
                          building_stories_min: this.props.currentProject.building_stories_max,
                          building_shape_type: [],
                          housing_plan_type: [],
                          my_building_shape_type: [],
                        },
                        () => {
                          // if (this.props.currentProject.auto_setting) {
                          //   this.props.onUpdateDefaults(value as BuildingType, this.props.currentProject.project_use_district);
                          // }
                          this.props.onUpdateDefaults(value as BuildingType, this.props.currentProject.project_use_district, "Value");
                        }
                      );
                    }}
                    {...this.props}
                  />

                  {available_list && available_list.length > 0 && (
                    <Fragment>
                      <div className="content description">설정하신 대지 영역에 법적으로 허용 가능한지,&nbsp; BUILDIT으로 설정 가능한지 확인 후 선택해주세요.</div>
                      <div className="content detail-description">BUILDIT이 ○표시 된 것은 설정이 가능합니다.</div>
                      <div className="content table">
                        <table>
                          <tr>
                            <th className="first"></th>
                            <th>법적 허용</th>
                            <th>BUILDIT</th>
                          </tr>
                          {available_list.map((e) => {
                            return (
                              <tr>
                                <td className="first">{e.building_type}</td>
                                <td>{e.허용여부 ? "○" : "×"}</td>
                                <td>{e.가능여부 ? "○" : "×"}</td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )) || (
                <div>
                  <div style={{ marginBottom: "5px", display: "flex", height: "20px", color: "#999", fontSize: "12px" }}>건물타입</div>
                  <div style={{ width: "360px", height: "40px", paddingLeft: "10px", display: "flex", alignItems: "center", fontSize: "14px", color: "#eee" }}>
                    {this.props.currentProject.building_type}
                  </div>
                  <div className="warning" style={{ color: "#48ddf2", fontSize: "13px", marginTop: "0px" }}>
                    * DESIGNER 프로젝트는 아파트로 제한됩니다.
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="display-flex">
            <Button onClick={(e) => this.props.setMore("BASIC")} disableRipple={true} className="btn bg-navy btn-primary btn-small more-btn">
              더보기
              {(this.props.more === false && <DownArrowIcon className="icon" />) || <UpArrowIcon className="icon" />}
            </Button>
          </div>
          {this.props.more && (
            <div className="more-fields">
              <div className="project-field">
                <ProjectFieldInput
                  type="text"
                  title="발주처"
                  className="width-half"
                  fieldName="ordering_companay"
                  fieldValue={this.props.currentProject.ordering_companay}
                  placeholder="입력"
                  disabledAutoSetting={true}
                  {...this.props}
                />
              </div>
              <div className="project-field m-l-a">
                <ProjectFieldInput
                  type="text"
                  title="발주 담당자"
                  className="width-half"
                  fieldName="ordering_manager"
                  fieldValue={this.props.currentProject.ordering_manager}
                  placeholder="입력"
                  disabledAutoSetting={true}
                  {...this.props}
                />
              </div>
              <div className="project-field">
                <ProjectFieldInput
                  type="text"
                  title="작성자"
                  className="width-half"
                  fieldName="drafter"
                  fieldValue={this.props.currentProject.drafter}
                  placeholder="입력"
                  disabledAutoSetting={true}
                  {...this.props}
                />
              </div>
              <div className="project-field m-l-a">
                <ProjectFieldInput
                  type="text"
                  title="작성자 연락처"
                  className="width-half"
                  fieldName="drafter_contact_number"
                  fieldValue={this.props.currentProject.drafter_contact_number}
                  placeholder="입력"
                  disabledAutoSetting={true}
                  {...this.props}
                />
              </div>
              <div className="project-field">
                <ProjectFieldInput
                  type="text"
                  title="관련 건축설계사무소"
                  className="width-half"
                  fieldName="design_office"
                  fieldValue={this.props.currentProject.design_office}
                  placeholder="입력"
                  disabledAutoSetting={true}
                  {...this.props}
                />
              </div>
              <div className="project-field m-l-a">
                <ProjectFieldInput
                  type="text"
                  title="시공사"
                  className="width-half"
                  fieldName="builder"
                  fieldValue={this.props.currentProject.builder}
                  placeholder="입력"
                  disabledAutoSetting={true}
                  {...this.props}
                />
              </div>
            </div>
          )}
        </div>
        {this.state.openModal && (
          <Modal
            open={this.state.openModal}
            type={(this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type) || "SIMPLE"}
            positive={this.state.modalOptions && this.state.modalOptions.positive}
            negative={this.state.modalOptions && this.state.modalOptions.negative}
            title={this.state.modalOptions && this.state.modalOptions.title}
            negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
            positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
            content={this.state.modalOptions && this.state.modalOptions.content}
            color={(this.state.modalOptions && this.state.modalOptions.color) || "DARK"}
          />
        )}
      </Fragment>
    );
  }
}


